<template>
  <ion-page>
    <Header />
    <ion-content :fullscreen="true">
      <div class="container">
        <div class="flex col-center mb-2">
          <h2 class="title">Buat Password Baru</h2>
          <div class="text-center text-sm">Password baru harus berbeda dengan password sebelumnya yang digunakan.</div>
        </div>
        <label for="phone">Password Baru</label>
        <ion-input type="password" class="input" v-model="password"></ion-input>
        <label for="phone">Konfirmasi Password Baru</label>
        <ion-input type="password" class="input" v-model="password_confirmation"></ion-input>

        <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="save">Reset Password</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInput, IonButton, toastController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    Header
  },
  data () {
    return {
      old_password: null,
      password: null,
      password_confirmation: null
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  },
  methods: {
    async save () {
      let res = await this.$api.post('/api/forgot/change-password', {
        password: this.password,
        password_confirmation: this.password_confirmation
      });

      if(! res.error) {
        this.$store.commit('auth/setUser', res.data)
        let toast = await toastController.create({
            message: 'sucess',
            duration: 2000,
            color: 'success',
          })
        toast.present()
        this.$router.push('/')
      }
    }
  }
})
</script>

<style>

</style>
